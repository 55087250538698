export const WhenTakenMoviesLogo = () => {
  return (
    <div className="relative top-1 inline-flex flex-row items-center gap-1.5">
      <div>
        <img src="/assets/images/clapperboard.png" className="size-10" />
      </div>
      <div className="hidden md:block text-2xl 2xl:text-4xl">
        <strong className="font-semibold leading-none">When</strong>
        <span className="font-light leading-none">Taken Movies</span>
      </div>
    </div>
  );
};
