import { getSiteMode } from './siteMode';

export function getFeedbackLink() {
  let autofill;
  if (getSiteMode() == 'whentaken') {
    autofill = 'WhenTaken';
  } else if (getSiteMode() == 'wheretaken') {
    autofill = '🆕 Wheretaken';
  } else if (getSiteMode() == 'whentaken-movies') {
    autofill = '🆕 Whentaken Movies';
  }
  if (autofill) {
    return `https://docs.google.com/forms/d/e/1FAIpQLSe-UDln8IMcm53NHYPt2m_a0XDyW1b6SCt8d-wxEmqYcMxYqw/viewform?entry.540162092=${autofill}`;
  }
  return 'https://forms.gle/AfRKMW3GkKT3oh49A';
}
