import { PUZZLE_LENGTH } from '@wt/app/game/_components/PuzzleLoader';
import { useRoundProgress } from '@wt/game/providers/roundProgress/RoundProgressProvider';
import { GameMode } from '@wt/game/utils/types';
import { memo } from 'react';

import { RoundProgress } from '../round-progress';
import { getMaxScore } from '@wt/app/game/_utils/getGameScore';

const GamePageHeaderContent = ({ gameMode }: { gameMode: GameMode }) => {
  const { score } = useRoundProgress();

  return (
    <>
      <div className="flex items-center justify-center rounded-full bg-info px-4 py-1 lg:hidden">
        <div className="flex items-end">
          <span className="text-lg font-bold leading-tight movies:dark:text-primary">
            {score}
          </span>
          <span className="text-sm font-bold text-info-content">
            /{getMaxScore() * PUZZLE_LENGTH}
          </span>
        </div>
      </div>
      <div className="hidden lg:flex">
        <RoundProgress gameMode={gameMode} />
      </div>
    </>
  );
};

export default memo(GamePageHeaderContent);
