'use client';

import { nullFunc } from '@wt/utilities/nullFunction';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRoundProgress } from '../roundProgress/RoundProgressProvider';
import {
  getAvailableHints as getAvailableHintsFromServer,
  getHint,
} from '@wt/utilities/database';
import { useRewardedAds } from '../ads/RewardedAdsProvider';
import { getSiteMode } from '@wt/utilities/siteMode';

export type YearHint = {
  year: number;
  diff: number;
  url: string;
};

export type LocationHint = {
  latitude: number;
  longitude: number;
  distance: number;
  url: string;
};

interface HintsContextType {
  hintsUsed: { [round: number]: string[] };
  hintsData: {
    [round: number]: { [hint: string]: any };
  };
  hintsModalOpen: boolean;
  setHintsModalOpen: (b: boolean) => void;
  useHint: (hint: string) => void;
  getAvailableHints: () => Promise<string[]>;
  hasUsedHintType: (hint: string) => boolean;
  hasUsedHint: (hint: string) => boolean;
  getUsedHints: (type?: string) => string[];
  getHintData: (hint: string) => any;
  setHintData: (hint: string, data: any) => void;
  shouldShowAd: () => boolean;
  trigger: string | undefined;
  setTrigger: (trigger: string | undefined) => void;
}

const initialContextValue: HintsContextType = {
  hintsUsed: {},
  hintsData: {},
  hintsModalOpen: false,
  setHintsModalOpen: nullFunc,
  useHint: nullFunc,
  getAvailableHints: async () => [],
  hasUsedHintType: () => false,
  hasUsedHint: () => false,
  getUsedHints: () => [],
  getHintData: () => null,
  setHintData: nullFunc,
  shouldShowAd: () => false,
  trigger: '',
  setTrigger: nullFunc,
};

const HintsContext = createContext<HintsContextType>(initialContextValue);

export const useHints = () => useContext(HintsContext);

export const getHintType = (hint: string) => {
  if (hint == 'proximitypic') return 'location';
  if (hint == 'dualdrop') return 'location';
  if (hint == 'eraexposure') return 'year';
  if (hint == 'timetidbit') return 'year';
  throw new Error('Unknown hint type: ' + hint);
};

const getTimeTidbit = async (year: number) => {
  const data = await fetch('/hints/timeTidbits.json');
  const json = (await data.json()) as any;
  const rows = json.filter((row: any) => row.year == year);
  if (rows.length == 0) {
    return 'No fact found';
  }
  return rows[Math.floor(Math.random() * rows.length)]?.fact ?? 'No fact found';
};

export const HintsProvider = ({ children }: { children: ReactNode }) => {
  const [hintsModalOpen, setHintsModalOpen] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<string>();
  const { runRewardedAd } = useRewardedAds();

  const [hintsUsed, setHintsUsed] = useState<{ [round: number]: string[] }>({});
  const [hintsData, setHintsData] = useState<{
    [round: number]: { [hint: string]: any };
  }>({});

  const { puzzleId, currentRound, puzzle } = useRoundProgress();

  const [currentPuzzleId, setCurrentPuzzleId] = useState<string>(
    puzzleId ?? ''
  );
  useEffect(() => {
    if (puzzleId != currentPuzzleId) {
      // A new puzzle, we clear hints used!
      setHintsUsed({});
      setHintsData({});
    }
    setCurrentPuzzleId(puzzleId ?? '');
  }, [puzzleId]);

  const getNumberUsedHints = useCallback(() => {
    let result = 0;
    for (const round in hintsUsed) {
      result += Object.keys(hintsUsed[round]).length;
    }
    return result;
  }, [hintsUsed]);

  const shouldShowAd = useCallback(() => {
    return getNumberUsedHints() > 0;
  }, [getNumberUsedHints]);

  const useHint = useCallback(
    async (hint: string, skipAd?: boolean) => {
      // TODO : Save guess data to in-progress guess, so a refresh keeps data?
      // TODO : Also load previous rounds guesses into this so that we remember how many hints have been used

      // If we've already used a hint of this type this round, then throw an error
      if (currentRound in hintsUsed) {
        const hintType = getHintType(hint);
        for (const hint of hintsUsed[currentRound]) {
          if (getHintType(hint) == hintType) {
            throw new Error("You've already used a hint of this type");
          }
        }
      }

      // If we've already used a hint, then show an advert!
      if (!skipAd) {
        if (shouldShowAd()) {
          try {
            runRewardedAd(() => {
              // Set some data which means this turns into "Use hint"
              useHint(hint, true);
            });
            return;
          } catch (e) {
            // Don't use hint if error
          }
        }
      }

      // Add the used hint, and initialise the data
      const newHintsUsed = { ...hintsUsed };
      if (!(currentRound in hintsUsed)) {
        newHintsUsed[currentRound] = [];
      }
      newHintsUsed[currentRound].push(hint);
      setHintsUsed(newHintsUsed);

      const newHintsData = { ...hintsData };
      if (!(currentRound in hintsData)) {
        newHintsData[currentRound] = {};
      }

      // Initial data should be based on the type of hint
      const data: any = {};

      if (hint != 'dualdrop') {
        // Which box has been opened?
        data.selectedBox = -1;
      }

      if (hint == 'proximitypic') {
        // proximitypic = the additional picture
        const h = (await getHint(
          puzzleId!,
          currentRound,
          hint
        )) as LocationHint;
        if (h) {
          const distance =
            h.distance < 250 ? 250 : h.distance < 500 ? 500 : 1000;
          data.text = `This picture is taken within ${distance} km of the main photo's location.`;
          data.image = h.url;
        } else {
          data.text = 'Error getting hint.';
        }
      } else if (hint == 'eraexposure') {
        // eraexposure = the additional picture
        const h = (await getHint(puzzleId!, currentRound, hint)) as YearHint;
        if (h) {
          const s = Math.abs(h.diff) != 1 ? 's' : '';
          data.text =
            h.diff == 0
              ? `This picture was taken the same year as the main photo.`
              : h.diff > 0
                ? `This picture was taken ${h.diff} year${s} after the main photo.`
                : `This picture was taken ${Math.abs(h.diff)} year${s} before the main photo.`;
          data.image = h.url;
        } else {
          data.text = 'Error getting hint.';
        }
      } else if (hint == 'timetidbit') {
        data.text = await getTimeTidbit(puzzle?.[currentRound - 1]?.year);
      }

      newHintsData[currentRound][hint] = data;
      setHintsData(newHintsData);
    },
    [
      currentRound,
      hintsUsed,
      setHintsUsed,
      hintsData,
      setHintsData,
      puzzle,
      puzzleId,
      shouldShowAd,
      runRewardedAd,
    ]
  );

  const getAvailableHints = useCallback(async () => {
    if (currentRound == 0) return [];
    if (getSiteMode() == 'whentaken-movies') {
      // Movies has no picture hints (yet)
      return ['dualdrop', 'timetidbit'];
    }
    const h = await getAvailableHintsFromServer(puzzleId!, currentRound);
    if (h) {
      return [...h, 'dualdrop', 'timetidbit'];
    } else {
      return [];
    }
  }, [getAvailableHintsFromServer, puzzleId, currentRound]);

  const hasUsedHintType = useCallback(
    (type: string) => {
      if (!(currentRound in hintsUsed)) {
        return false;
      }

      for (const h of hintsUsed[currentRound]) {
        if (type == getHintType(h)) {
          return true;
        }
      }

      return false;
    },
    [currentRound, hintsUsed]
  );

  const hasUsedHint = useCallback(
    (hint: string) => {
      if (!(currentRound in hintsUsed)) {
        return false;
      }

      for (const h of hintsUsed[currentRound]) {
        if (hint == h) {
          return true;
        }
      }

      return false;
    },
    [currentRound, hintsUsed]
  );

  const getUsedHints = useCallback(
    (type?: string) => {
      const result: string[] = [];
      if (currentRound in hintsUsed) {
        for (const h of hintsUsed[currentRound]) {
          if (!type || type == getHintType(h)) {
            result.push(h);
          }
        }
      }

      return result;
    },
    [currentRound, hintsUsed]
  );

  const getHintData = useCallback(
    (hint: string) => {
      if (!(currentRound in hintsData)) {
        return null;
      }

      if (!(hint in hintsData[currentRound])) {
        return null;
      }

      return hintsData[currentRound][hint];
    },
    [currentRound, hintsData]
  );

  const setHintData = useCallback(
    (hint: string, data: any) => {
      if (!(currentRound in hintsData)) {
        return;
      }

      if (!(hint in hintsData[currentRound])) {
        return;
      }

      const newHintsData = {
        ...hintsData,
        [currentRound]: {
          ...hintsData[currentRound],
          [hint]: data,
        },
      };

      setHintsData(newHintsData);
    },
    [currentRound, hintsData, setHintsData]
  );

  return (
    <HintsContext.Provider
      value={{
        hintsUsed,
        hintsData,
        hintsModalOpen,
        setHintsModalOpen,
        useHint,
        getAvailableHints,
        hasUsedHintType,
        hasUsedHint,
        getUsedHints,
        getHintData,
        setHintData,
        shouldShowAd,
        trigger,
        setTrigger,
      }}
    >
      {children}
    </HintsContext.Provider>
  );
};
