import * as whentakenThemes from '@wt/styles/themes/whentaken';
import * as whentakenMoviesThemes from '@wt/styles/themes/whentaken-movies';
import * as wheretakenThemes from '@wt/styles/themes/wheretaken';

import {
  firstWhenTakenGameDate,
  firstWhereTakenGameDate,
  firstWhenTakenMoviesGameDate,
} from '@wt/app/admin/constants';

export function getSiteMode(): 'whentaken' | 'wheretaken' | 'whentaken-movies' {
  const siteMode = process.env.NEXT_PUBLIC_SITE_MODE ?? 'whentaken';
  if (siteMode == 'wheretaken') {
    return 'wheretaken';
  }
  if (siteMode == 'whentaken-movies') {
    return 'whentaken-movies';
  }
  return 'whentaken';
}

export function getSiteName() {
  if (getSiteMode() == 'wheretaken') return 'WhereTaken';
  if (getSiteMode() == 'whentaken-movies') return 'WhenTaken Movies';
  return 'WhenTaken';
}

export function getSiteURL() {
  if (getSiteMode() == 'wheretaken') return 'https://wheretaken.com';
  if (getSiteMode() == 'whentaken-movies')
    return 'https://movies.whentaken.com';
  return 'https://whentaken.com';
}

export function getCDNURL() {
  if (getSiteMode() == 'wheretaken') return 'https://cdn.wheretaken.com';
  if (getSiteMode() == 'whentaken-movies')
    return 'https://cdn.movies.whentaken.com';
  return 'https://cdn.whentaken.com';
}

export function getFirstGameDate() {
  if (getSiteMode() == 'wheretaken') {
    return firstWhereTakenGameDate;
  }
  if (getSiteMode() == 'whentaken-movies') {
    return firstWhenTakenMoviesGameDate;
  }
  return firstWhenTakenGameDate;
}

export function getSiteThemes() {
  if (getSiteMode() == 'wheretaken') {
    return {
      lightTheme: wheretakenThemes.lightTheme,
      darkTheme: wheretakenThemes.darkTheme,
    };
  }
  if (getSiteMode() == 'whentaken-movies') {
    return {
      lightTheme: whentakenMoviesThemes.lightTheme,
      darkTheme: whentakenMoviesThemes.darkTheme,
    };
  }
  return {
    lightTheme: whentakenThemes.lightTheme,
    darkTheme: whentakenThemes.darkTheme,
  };
}
