import { getSiteMode } from '@wt/utilities/siteMode';

type CommandArray = Array<(this: typeof globalThis) => void> | undefined;
declare global {
  interface Window {
    snigelPubConf: {
      adengine: {
        activeAdUnits: string[];
      };
    };
    dataLayer?: any;
    activeUnits?: string[];
    adngin?: {
      adnginLoaderReady?: boolean;
      cmd: {
        startAuction: (any: any) => void;
      };
      queue?: CommandArray;
    };
    destroyRefreshTopAd?: any;
    gtag?: any;
  }
}

// mobile and desktop
export function refreshBannerAd() {
  if (
    window.adngin !== undefined &&
    window.adngin.cmd !== undefined &&
    typeof window.adngin.cmd.startAuction === 'function'
  ) {
    window.adngin.cmd.startAuction(['top_banner']);
  }
}

// mobile and desktop
export function destroyBannerAd() {
  if (
    window.adngin !== undefined &&
    document.getElementById('adngin-top_banner-0')
  ) {
    Array.from(window.googletag.pubads().getSlots()).forEach((slot) => {
      if (slot.getSlotElementId() === 'adngin-top_banner-0') {
        window.googletag.destroySlots([slot]);
      }
    });
  }
}

// desktop
export function refreshSidebarAd() {
  if (window.innerWidth < 1024) return;
  if (
    window.adngin !== undefined &&
    window.adngin.cmd !== undefined &&
    typeof window.adngin.cmd.startAuction === 'function'
  ) {
    window.adngin.cmd.startAuction(['sidebar']);
  }
}

// desktop
export function refreshVideoAd() {
  if (window.innerWidth < 1024) return;
  if (
    window.adngin !== undefined &&
    window.adngin.cmd !== undefined &&
    typeof window.adngin.cmd.startAuction === 'function'
  ) {
    window.adngin.cmd.startAuction(['video']);
  }
}

// desktop
export function destroyVideoAd() {
  if (window.innerWidth < 1024) return;
  if (
    window.adngin !== undefined &&
    document.getElementById('adngin-video-0')
  ) {
    Array.from(window.googletag.pubads().getSlots()).forEach((slot) => {
      if (slot.getSlotElementId() === 'adngin-video-0') {
        window.googletag.destroySlots([slot]);
      }
    });
  }
}

// desktop
export function refreshArchiveSidebarAd() {
  if (window.innerWidth < 1200) return;
  if (
    window.adngin !== undefined &&
    window.adngin.cmd !== undefined &&
    typeof window.adngin.cmd.startAuction === 'function'
  ) {
    window.adngin.cmd.startAuction(['archive_sidebar']);
  }
}

export function getAdScript(): HTMLScriptElement {
  const href = window.location.href;
  let src = '';
  const siteMode = getSiteMode();
  const url =
    siteMode == 'whentaken-movies'
      ? 'movies.whentaken.com'
      : siteMode == 'wheretaken'
        ? 'wheretaken.com'
        : 'whentaken.com';
  if (
    href.includes('staging') ||
    href.includes('localhost') ||
    href.includes('pages.dev')
  ) {
    console.log('Loading STAGING ads script');
    src = `https://staging-cdn.snigelweb.com/adengine/${url}/loader.js`;
  } else {
    console.log('Loading LIVE ads script');
    src = `https://cdn.snigelweb.com/adengine/${url}/loader.js`;
  }

  const node = document.createElement('script');
  node.src = src;
  node.type = 'text/javascript';
  node.async = true;
  node.setAttribute('data-cfasync', 'false');
  return node;
}

export function loadAdScript(): void {
  try {
    const node = getAdScript();
    document.getElementsByTagName('head')[0].appendChild(node);
  } catch (e) {
    console.log('error loading ad script: ', e);
  }
}

export function removeAdScript(): void {
  try {
    const node = getAdScript();
    document.getElementsByTagName('head')[0].removeChild(node);
  } catch (e) {
    console.log('error removing ad script: ', e);
  }
}
